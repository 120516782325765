import { defineStore, storeToRefs } from 'pinia'
import type { Card as ProductCard } from '@academica-box/components/Cards/UiProductCard/types'
import { usePromoStore } from '@academica-box/composables/store/promo'
import { useSubscribesStore } from '@academica-box/composables/store/subscribes'
import type { Filters } from '@academica-box/api/courses'
import type { Pagination } from '@academica-box/components/Blocks/UiPagination/types'

export const useCoursesStore = defineStore('courses', {
  state: () => ({
    courses: null,
    pagination: null,
    isLoading: false,
  } as {
    courses: ProductCard[] | null;
    pagination: Pagination | null;
    isLoading: boolean;
  }),

  actions: {
    async getCourses(filters?: Filters) {
      const promoStore = usePromoStore()
      const subscribesStore = useSubscribesStore()
      const { isSubscriptionAvailable } = storeToRefs(subscribesStore)
      this.isLoading = true

      const { $api } = useNuxtApp()

      try {
        const response = await $api.courses.getCoursesByFilters(filters, { isPromo: Boolean(promoStore.promo), isSubscriptionAvailable: isSubscriptionAvailable.value })

        this.courses = response.courses
        this.pagination = response.pagination

        return response
      } catch (error) {
        throw showError({ statusCode: 404 })
      } finally {
        this.isLoading = false
      }
    },
  }
})
